@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap");
:root {
  --box-shadow: 0px 9px 27px -5px hsla(236, 20%, 10%, 0.5), 0px 5px 12px -8px hsl(236 20% 20% / 0.75);
  --box-shadow-hidden: 0px 9px 27px -5px hsla(236, 20%, 10%, 0), 0px 5px 12px -8px hsl(236 20% 20% / 0);
  --colour-background-hsl: 0deg 0% 100%;
  --colour-background-light-hsl: 0deg 0% 100%;
  --colour-background-lighter-hsl: 0deg 0% 100%;
  --colour-background-lightest-hsl: 0deg 0% 100%;
  --colour-background-dark-hsl: 0deg 0% 75%;
  --colour-background-darker-hsl: 0deg 0% 50%;
  --colour-background-darkest-hsl: 0deg 0% 25%;
  --colour-font-hsl: 240deg 18% 24%;
  --colour-font-light-hsl: 240deg 18% 43%;
  --colour-font-lighter-hsl: 240deg 18% 62%;
  --colour-font-lightest-hsl: 240deg 18% 81%;
  --colour-font-dark-hsl: 240deg 18% 18%;
  --colour-font-darker-hsl: 240deg 18% 12%;
  --colour-font-darkest-hsl: 240deg 18% 6%;
  --colour-kpp-cream-hsl: 46deg 100% 89%;
  --colour-kpp-cream-light-hsl: 46deg 99% 91%;
  --colour-kpp-cream-lighter-hsl: 46deg 98% 93%;
  --colour-kpp-cream-lightest-hsl: 46deg 97% 96%;
  --colour-kpp-cream-dark-hsl: 46deg 70% 80%;
  --colour-kpp-cream-darker-hsl: 46deg 40% 70%;
  --colour-kpp-cream-darkest-hsl: 45deg 25% 60%;
  --colour-kpp-charcoal-hsl: 227deg 6% 30%;
  --colour-kpp-charcoal-light-hsl: 227deg 6% 48%;
  --colour-kpp-charcoal-lighter-hsl: 227deg 6% 65%;
  --colour-kpp-charcoal-lightest-hsl: 227deg 6% 83%;
  --colour-kpp-charcoal-dark-hsl: 227deg 6% 23%;
  --colour-kpp-charcoal-darker-hsl: 227deg 6% 15%;
  --colour-kpp-charcoal-darkest-hsl: 227deg 6% 8%;
  --colour-highlight-hsl: 44deg 100% 47%;
}

.backgroundColor-kppCream {
  background-color: hsl(var(--colour-kpp-cream-hsl));
}

.backgroundColor-kppCream-light {
  background-color: hsl(var(--colour-kpp-cream-light-hsl));
}

.backgroundColor-kppCream-lighter {
  background-color: hsl(var(--colour-kpp-cream-lighter-hsl));
}

.backgroundColor-kppCream-lightest {
  background-color: hsl(var(--colour-kpp-cream-lightest-hsl));
}

.backgroundColor-kppCream-dark {
  background-color: hsl(var(--colour-kpp-cream-dark-hsl));
}

.backgroundColor-kppCream-darker {
  background-color: hsl(var(--colour-kpp-cream-darker-hsl));
}

.backgroundColor-kppCream-darkest {
  background-color: hsl(var(--colour-kpp-cream-darkest-hsl));
}

.backgroundColor-kppCharcoal {
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
}

.backgroundColor-kppCharcoal-light {
  background-color: hsl(var(--colour-kpp-charcoal-light-hsl));
}

.backgroundColor-kppCharcoal-lighter {
  background-color: hsl(var(--colour-kpp-charcoal-lighter-hsl));
}

.backgroundColor-kppCharcoal-lightest {
  background-color: hsl(var(--colour-kpp-charcoal-lightest-hsl));
}

.backgroundColor-kppCharcoal-dark {
  background-color: hsl(var(--colour-kpp-charcoal-dark-hsl));
}

.backgroundColor-kppCharcoal-darker {
  background-color: hsl(var(--colour-kpp-charcoal-darker-hsl));
}

.backgroundColor-kppCharcoal-darkest {
  background-color: hsl(var(--colour-kpp-charcoal-darkest-hsl));
}

:root {
  --fontFamily: "Lato", sans-serif;
  --fontFamily-feature: "Dala Floda Web", cursive;
  --fontSize-xsmall: 0.75rem;
  --fontSize-small: 0.85rem;
  --fontSize-regular: clamp(1rem, 3cqw, 1.25rem);
  --fontSize-medium: clamp(1.25rem, 5cqw, 2rem);
  --fontSize-large: clamp(2.5rem, 10cqw, 4rem);
  --fontSize-xlarge: clamp(3rem, 10cqw, 6rem);
}

@font-face {
  font-family: "Dala Floda Web";
  src: url("../font/DalaFloda/Dala-Floda-LF-Web-Medium.eot");
  src: url("../font/DalaFloda/Dala-Floda-LF-Web-Medium.eot?#iefix") format("embedded-opentype"), url("../font/DalaFloda/Dala-Floda-LF-Web-Medium.woff2") format("woff2"), url("../font/DalaFloda/Dala-Floda-LF-Web-Medium.ttf") format("truetype"), url("../font/DalaFloda/Dala-Floda-LF-Web-Medium.svg#Dala-Floda-LF-Web-Medium") format("svg");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
:root {
  --maxWidth: 1200px;
  --maxWidthLarge: 1920px;
  --contentWidth: min(90%, var(--maxWidth));
  --contentWidthLarge: min(90%, var(--maxWidthLarge));
  --dialogWidth: min(90%, 750px);
  --border-radius: 6px;
  --headerHeight: 5rem;
  --baseSize: 16px;
  --xsmall: calc(var(--baseSize) / 2);
  --small: calc(var(--baseSize));
  --medium: calc(var(--baseSize) * 2);
  --large: calc(var(--baseSize) * 4);
  --xlarge: calc(var(--baseSize) * 8);
}

.grid {
  display: grid;
}

.grid-wrapper {
  display: grid;
  padding-inline: var(--small);
  gap: 4px;
}
.grid-wrapper > * {
  overflow: hidden;
  border-radius: var(--border-radius);
}

.columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.columns-2_3 {
  grid-template-columns: 2fr 1fr;
}

.columnsAuto-2 {
  grid-template-columns: auto auto;
}

.columnsAutoFree-2 {
  grid-template-columns: auto 1fr;
}

.columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

.span-col2 {
  grid-column-end: span 2;
}

.span-col3 {
  grid-column-end: span 2;
}

.span-row2 {
  grid-row-end: span 2;
}

.span-row3 {
  grid-row-end: span 3;
}

.flex, .flexVert {
  display: flex;
}
.flexVert {
  flex-direction: column;
}

.gap-2 {
  gap: 2px;
}

.jContent-spaceBetween {
  justify-content: space-between;
}

.jContent-spaceAround {
  justify-content: space-around;
}

.jContent-start {
  justify-content: start;
}

.jContent-center {
  justify-content: center;
}

.jContent-end {
  justify-content: end;
}

.jContent-stretch {
  justify-content: stretch;
}

.aContent-start {
  align-content: flex-start;
}

.aContent-center {
  align-content: center;
}

.aContent-end {
  align-content: flex-end;
}

.aItems-start {
  align-items: start;
}

.aItems-end {
  align-items: end;
}

.aItems-center {
  align-items: center;
}

.aItems-stretch {
  align-items: stretch;
}

.jItems-start {
  justify-items: start;
}

.jItems-end {
  justify-items: end;
}

.jItems-center {
  justify-items: center;
}

.jItems-stretch {
  justify-items: stretch;
}

.aSelf-end {
  align-self: end;
}

.jSelf-end {
  justify-self: end;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: hsl(var(--colour-font-hsl));
  background-color: hsl(var(--colour-background-hsl));
}

.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}
.appHeader {
  --height: var(--headerHeight);
  --fontColour: var(--colour-kpp-cream-hsl);
  --backgroundColour: var(--colour-kpp-charcoal-hsl);
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  column-gap: var(--medium);
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--height);
  margin: var(--small);
  padding-inline: var(--medium);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: hsl(var(--backgroundColour));
  backdrop-filter: blur(6px);
  transition: box-shadow 300ms ease 600ms;
}
.appHeader .appHeader-logo {
  height: 2.5rem;
}
.appHeader .appHeader-logo svg {
  fill: hsl(var(--fontColour));
}
.appHeader[data-mobilenav-visible=true] {
  box-shadow: var(--box-shadow-hidden);
  transition: box-shadow 500ms ease;
}
.app > main {
  position: relative;
  margin-top: calc(var(--headerHeight) + var(--small) * 2);
}
.appFooter {
  margin: var(--small);
  padding-block: var(--large) var(--medium);
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
  border-radius: var(--border-radius);
  color: hsl(var(--colour-kpp-cream-hsl));
  line-height: 1;
}
.appFooter-content {
  width: min(720px, 90%);
  margin: 0 auto;
}
.appFooter-logo {
  fill: hsl(var(--colour-kpp-cream-hsl));
  height: 3rem;
}
.appFooter-legal {
  color: hsl(var(--colour-kpp-charcoal-lighter-hsl));
}
.appFooter-legal a {
  color: hsl(var(--colour-kpp-charcoal-lighter-hsl));
}
.appFooter-legal a:hover {
  color: hsl(var(--colour-kpp-charcoal-lightest-hsl));
}
.appFooter .socialIcon {
  width: 2rem;
}
.appFooter .socialIcon:hover #circle-outline {
  stroke: hsl(var(--colour-highlight-hsl));
}
.appFooter .socialIcon:hover #icon {
  fill: hsl(var(--colour-highlight-hsl));
}
.appNav {
  display: flex;
  column-gap: var(--medium);
}
.appNav a {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.appNav a[data-active-location=true] {
  color: hsl(var(--colour-highlight-hsl));
}
.appNavMobile {
  display: none;
  z-index: 2;
  flex-direction: column;
  row-gap: var(--medium);
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
  scale: 0;
  opacity: 0;
  font-size: var(--fontSize-large);
}
.appNavMobile a {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.appNavMobile a[data-active-location=true] {
  color: hsl(var(--colour-highlight-hsl));
}

.content-width {
  width: var(--contentWidth);
  margin: 0 auto;
}

.container {
  container-type: inline-size;
}

.border-rounded {
  border-radius: var(--border-radius);
}

.margin-xsmall {
  margin: var(--xsmall);
}

.marginBlock-xsmall {
  margin-block: var(--xsmall);
}

.marginInline-xsmall {
  margin-inline: var(--xsmall);
}

.marginTop-xsmall {
  margin-top: var(--xsmall);
}

.marginBottom-xsmall {
  margin-bottom: var(--xsmall);
}

.marginLeft-xsmall {
  margin-left: var(--xsmall);
}

.marginRight-xsmall {
  margin-right: var(--xsmall);
}

.margin-small {
  margin: var(--small);
}

.marginBlock-small {
  margin-block: var(--small);
}

.marginInline-small {
  margin-inline: var(--small);
}

.marginTop-small {
  margin-top: var(--small);
}

.marginBottom-small {
  margin-bottom: var(--small);
}

.marginLeft-small {
  margin-left: var(--small);
}

.marginRight-small {
  margin-right: var(--small);
}

.margin-medium {
  margin: var(--medium);
}

.marginBlock-medium {
  margin-block: var(--medium);
}

.marginInline-medium {
  margin-inline: var(--medium);
}

.marginTop-medium {
  margin-top: var(--medium);
}

.marginBottom-medium {
  margin-bottom: var(--medium);
}

.marginLeft-medium {
  margin-left: var(--medium);
}

.marginRight-medium {
  margin-right: var(--medium);
}

.margin-large {
  margin: var(--large);
}

.marginBlock-large {
  margin-block: var(--large);
}

.marginInline-large {
  margin-inline: var(--large);
}

.marginTop-large {
  margin-top: var(--large);
}

.marginBottom-large {
  margin-bottom: var(--large);
}

.marginLeft-large {
  margin-left: var(--large);
}

.marginRight-large {
  margin-right: var(--large);
}

.margin-xlarge {
  margin: var(--xlarge);
}

.marginBlock-xlarge {
  margin-block: var(--xlarge);
}

.marginInline-xlarge {
  margin-inline: var(--xlarge);
}

.marginTop-xlarge {
  margin-top: var(--xlarge);
}

.marginBottom-xlarge {
  margin-bottom: var(--xlarge);
}

.marginLeft-xlarge {
  margin-left: var(--xlarge);
}

.marginRight-xlarge {
  margin-right: var(--xlarge);
}

.margin-none {
  margin: 0;
}

.marginBlock-none {
  margin-block: 0;
}

.marginInline-none {
  margin-inline: 0;
}

.marginTop-none {
  margin-top: 0;
}

.marginBottom-none {
  margin-bottom: 0;
}

.marginLeft-none {
  margin-left: 0;
}

.marginRight-none {
  margin-right: 0;
}

.padding-xsmall {
  padding: var(--xsmall);
}

.paddingBlock-xsmall {
  padding-block: var(--xsmall);
}

.paddingInline-xsmall {
  padding-inline: var(--xsmall);
}

.paddingTop-xsmall {
  padding-top: var(--xsmall);
}

.paddingBottom-xsmall {
  padding-bottom: var(--xsmall);
}

.paddingLeft-xsmall {
  padding-left: var(--xsmall);
}

.paddingRight-xsmall {
  padding-right: var(--xsmall);
}

.padding-small {
  padding: var(--small);
}

.paddingBlock-small {
  padding-block: var(--small);
}

.paddingInline-small {
  padding-inline: var(--small);
}

.paddingTop-small {
  padding-top: var(--small);
}

.paddingBottom-small {
  padding-bottom: var(--small);
}

.paddingLeft-small {
  padding-left: var(--small);
}

.paddingRight-small {
  padding-right: var(--small);
}

.padding-medium {
  padding: var(--medium);
}

.paddingBlock-medium {
  padding-block: var(--medium);
}

.paddingInline-medium {
  padding-inline: var(--medium);
}

.paddingTop-medium {
  padding-top: var(--medium);
}

.paddingBottom-medium {
  padding-bottom: var(--medium);
}

.paddingLeft-medium {
  padding-left: var(--medium);
}

.paddingRight-medium {
  padding-right: var(--medium);
}

.padding-large {
  padding: var(--large);
}

.paddingBlock-large {
  padding-block: var(--large);
}

.paddingInline-large {
  padding-inline: var(--large);
}

.paddingTop-large {
  padding-top: var(--large);
}

.paddingBottom-large {
  padding-bottom: var(--large);
}

.paddingLeft-large {
  padding-left: var(--large);
}

.paddingRight-large {
  padding-right: var(--large);
}

.padding-xlarge {
  padding: var(--xlarge);
}

.paddingBlock-xlarge {
  padding-block: var(--xlarge);
}

.paddingInline-xlarge {
  padding-inline: var(--xlarge);
}

.paddingTop-xlarge {
  padding-top: var(--xlarge);
}

.paddingBottom-xlarge {
  padding-bottom: var(--xlarge);
}

.paddingLeft-xlarge {
  padding-left: var(--xlarge);
}

.paddingRight-xlarge {
  padding-right: var(--xlarge);
}

.padding-none {
  padding: 0;
}

.paddingBlock-none {
  padding-block: 0;
}

.paddingInline-none {
  padding-inline: 0;
}

.paddingTop-none {
  padding-top: 0;
}

.paddingBottom-none {
  padding-bottom: 0;
}

.paddingLeft-none {
  padding-left: 0;
}

.paddingRight-none {
  padding-right: 0;
}

.gap-xsmall {
  gap: var(--xsmall);
}

.gapRow-xsmall {
  row-gap: var(--xsmall);
}

.gapCol-xsmall {
  column-gap: var(--xsmall);
}

.gap-small {
  gap: var(--small);
}

.gapRow-small {
  row-gap: var(--small);
}

.gapCol-small {
  column-gap: var(--small);
}

.gap-medium {
  gap: var(--medium);
}

.gapRow-medium {
  row-gap: var(--medium);
}

.gapCol-medium {
  column-gap: var(--medium);
}

.gap-large {
  gap: var(--large);
}

.gapRow-large {
  row-gap: var(--large);
}

.gapCol-large {
  column-gap: var(--large);
}

.gap-xlarge {
  gap: var(--xlarge);
}

.gapRow-xlarge {
  row-gap: var(--xlarge);
}

.gapCol-xlarge {
  column-gap: var(--xlarge);
}

.gap-none {
  gap: 0;
}

.gapRow-none {
  row-gap: 0;
}

.gapCol-none {
  column-gap: 0;
}

.plusPattern {
  position: relative;
  background-image: url("/src/assets/img/plusPattern.svg");
  background-repeat: repeat;
}

.circlePattern {
  background-image: url("/src/assets/img/circlePattern.svg");
  background-repeat: repeat;
}

.summary {
  line-height: 2;
  font-size: 2rem;
  padding: var(--large);
}
.summary b,
.summary strong {
  color: hsl(var(--colour-highlight-hsl));
}

@media (max-width: 700px) {
  .divider {
    display: none;
  }

  .appFooter-links, .appFooter-legal {
    flex-direction: column;
  }

  .appNav {
    display: none;
  }

  .appNavMobile {
    display: flex;
  }
}
* {
  font-family: var(--fontFamily);
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  line-height: 1.1;
}

h1 {
  font-size: 5rem;
  line-height: 0.9;
}

h2 {
  font-size: 2rem;
}
h2 + p {
  margin-top: var(--small);
}

p {
  line-height: 1.6;
}
p + p {
  margin-top: var(--medium);
}
p + h2 {
  margin-top: var(--large);
}

a {
  text-decoration: none;
  color: hsl(var(--colour-font-hsl));
  transition: color 350ms ease;
}
a:hover {
  color: hsl(var(--colour-highlight-hsl));
}

a.link-light:not(:hover) {
  color: hsl(var(--colour-kpp-cream-hsl));
}

a.link-dark:not(:hover) {
  color: hsl(var(--colour-kpp-charcoal-dark-hsl));
}

.fontFamily-feature {
  font-family: var(--fontFamily-feature);
}
.fontColour-white {
  color: white;
}
.fontColour-light {
  color: hsl(var(--colour-font-light-hsl));
}
.fontColour-lighter {
  color: hsl(var(--colour-font-lighter-hsl));
}
.fontColour-lightest {
  color: hsl(var(--colour-font-lightest-hsl));
}
.fontColour-dark {
  color: hsl(var(--colour-font-dark-hsl));
}
.fontColour-darker {
  color: hsl(var(--colour-font-darker-hsl));
}
.fontColour-darkest {
  color: hsl(var(--colour-font-darkest-hsl));
}
.fontColour-cream {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.fontColour-creamLight {
  color: hsl(var(--colour-kpp-cream-light-hsl));
}
.fontColour-creamLighter {
  color: hsl(var(--colour-kpp-cream-lighter-hsl));
}
.fontColour-creamLightest {
  color: hsl(var(--colour-kpp-cream-lightest-hsl));
}
.fontColour-cream {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.fontColour-creamDark {
  color: hsl(var(--colour-kpp-cream-dark-hsl));
}
.fontColour-creamDarker {
  color: hsl(var(--colour-kpp-cream-darker-hsl));
}
.fontColour-creamDarkest {
  color: hsl(var(--colour-kpp-cream-darkest-hsl));
}
.fontColour-charcoal {
  color: hsl(var(--colour-kpp-charcoal-hsl));
}
.fontColour-charcoalLight {
  color: hsl(var(--colour-kpp-charcoal-light-hsl));
}
.fontColour-charcoalLighter {
  color: hsl(var(--colour-kpp-charcoal-lighter-hsl));
}
.fontColour-charcoalLightest {
  color: hsl(var(--colour-kpp-charcoal-lightest-hsl));
}
.fontColour-charcoal {
  color: hsl(var(--colour-kpp-charcoal-hsl));
}
.fontColour-charcoalDark {
  color: hsl(var(--colour-kpp-charcoal-dark-hsl));
}
.fontColour-charcoalDarker {
  color: hsl(var(--colour-kpp-charcoal-darker-hsl));
}
.fontColour-charcoalDarkest {
  color: hsl(var(--colour-kpp-charcoal-darkest-hsl));
}
.fontColour-error {
  color: hsl(var(--colour-highlight-hsl));
}
.fontSize-standard {
  font-size: var(--fontSize-regular);
}
.fontSize-small {
  font-size: var(--fontSize-small);
}
.fontSize-medium {
  font-size: var(--fontSize-medium);
}
.fontSize-large {
  font-size: var(--fontSize-large);
}
.fontWeight-bold {
  font-weight: bold;
}

.textLineHeight-xsmall {
  line-height: 0.9;
}
.textLineHeight-small {
  line-height: 1;
}
.textLineHeight-large {
  line-height: 1.6;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-maxWidth {
  max-width: 45ch;
}
.text-vertical {
  line-height: 1;
  writing-mode: vertical-lr;
}
.text-vertical-alt {
  rotate: 180deg;
}

.limitLines-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

svg {
  transition: fill 350ms ease;
}

.underline-wrapper:is(:hover) svg,
.underlineVertical:is(:hover) svg {
  fill: hsl(var(--colour-highlight-hsl));
}

.underline-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.underline-wrapper:focus-visible {
  outline: none;
}

:is(.underline-top, .underline-bottom) .underline {
  --bottom-offset: 0.75rem;
  --width-offset: 0.25rem;
  --tickColour: hsl(var(--colour-kpp-charcoal-lighter-hsl));
  position: absolute;
  bottom: calc(-1 * var(--bottom-offset));
  height: 1px;
  width: 0;
  background-color: var(--tickColour);
  transition: width 200ms ease;
}
:is(.underline-top, .underline-bottom) .underline .underline-value {
  opacity: 0;
  width: 100%;
  padding-top: 1px;
  text-align: center;
  font-size: 0.5rem;
  color: var(--tickColour);
}
:is(.underline-top, .underline-bottom) .underline .underline-left,
:is(.underline-top, .underline-bottom) .underline .underline-right {
  position: absolute;
  bottom: 0px;
  height: 0;
  width: 1px;
  background-color: var(--tickColour);
  transition: height 75ms ease 125ms;
}
:is(.underline-top, .underline-bottom) .underline .underline-left {
  left: var(--width-offset);
}
:is(.underline-top, .underline-bottom) .underline .underline-right {
  right: var(--width-offset);
}
:is(.underline-top, .underline-bottom) .underline::before, :is(.underline-top, .underline-bottom) .underline::after {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 1px;
  width: 0px;
  background-color: var(--tickColour);
  rotate: -45deg;
  transition: width 75ms ease 75ms;
}
:is(.underline-top, .underline-bottom) .underline::before {
  left: 0;
}
:is(.underline-top, .underline-bottom) .underline::after {
  right: 0;
}

.underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline {
  width: calc(100% + var(--width-offset) * 2);
}
.underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline .underline-value,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline .underline-value {
  opacity: 1;
}
.underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline .underline-left,
.underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline .underline-right,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline .underline-left,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline .underline-right {
  height: var(--bottom-offset);
}
.underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline::before, .underline-target:is(:hover, :focus-visible):is(.underline-top, .underline-bottom) .underline::after,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline::before,
.underline-target:is(:hover, :focus-visible) :is(.underline-top, .underline-bottom) .underline::after {
  width: 11px;
}

:is(.underline-top) .underline {
  top: calc(-1 * var(--bottom-offset));
}
:is(.underline-top) .underline .underline-value {
  padding-bottom: 0px;
  translate: 0 -0.75rem;
}
:is(.underline-top) .underline .underline-left,
:is(.underline-top) .underline .underline-right {
  top: 0px;
}

:is(.underline-left, .underline-right) {
  flex-direction: row;
}
:is(.underline-left, .underline-right) .underline {
  z-index: 100;
  --bottom-offset: 0.75rem;
  --width-offset: 0.25rem;
  --tickColour: hsl(var(--colour-kpp-charcoal-lighter-hsl));
  position: absolute;
  right: calc(-1 * var(--bottom-offset) - var(--bottom-offset));
  height: 0px;
  width: 1px;
  background-color: var(--tickColour);
  transition: height 200ms ease;
  display: flex;
}
:is(.underline-left, .underline-right) .underline .underline-value {
  opacity: 0;
  height: 100%;
  padding-right: 3px;
  text-align: center;
  font-size: 0.5rem;
  color: var(--tickColour);
  line-height: 1;
  writing-mode: vertical-lr;
  rotate: 180deg;
}
:is(.underline-left, .underline-right) .underline .underline-left,
:is(.underline-left, .underline-right) .underline .underline-right {
  position: absolute;
  right: 0px;
  height: 1px;
  width: 0;
  background-color: var(--tickColour);
  transition: width 75ms ease 125ms;
}
:is(.underline-left, .underline-right) .underline .underline-left {
  top: 5px;
}
:is(.underline-left, .underline-right) .underline .underline-right {
  bottom: 5px;
}
:is(.underline-left, .underline-right) .underline::before, :is(.underline-left, .underline-right) .underline::after {
  content: "";
  position: absolute;
  right: -5px;
  height: 1px;
  width: 0px;
  background-color: var(--tickColour);
  rotate: -45deg;
  transition: width 75ms ease 75ms;
}
:is(.underline-left, .underline-right) .underline::before {
  top: var(--width-offset);
}
:is(.underline-left, .underline-right) .underline::after {
  bottom: var(--width-offset);
}
:is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline {
  height: calc(100% + var(--width-offset) * 2);
}
:is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline .underline-value {
  opacity: 1;
}
:is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline .underline-left,
:is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline .underline-right {
  width: var(--bottom-offset);
}
:is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline::before, :is(.underline-left, .underline-right):is(:hover, :focus-visible) .underline::after {
  width: 11px;
}

:is(.underline-left) .underline {
  left: calc(-1 * var(--bottom-offset) - var(--bottom-offset));
}
:is(.underline-left) .underline .underline-value {
  padding-right: 0px;
  translate: -0.75rem 0;
}
:is(.underline-left) .underline .underline-left,
:is(.underline-left) .underline .underline-right {
  left: 0px;
}

.link-image {
  height: 100%;
  background-color: hsl(var(--colour-kpp-cream-hsl));
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 33%;
  overflow: hidden;
  container-type: size;
}
.link-image .image {
  grid-area: 1/1/3/2;
  transition: scale 500ms ease, clip-path 500ms ease, filter 150ms ease;
}
.link-image .image img {
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.link-image .title {
  grid-area: 2/1/3/2;
  z-index: 2;
  display: flex;
  align-items: end;
  padding: var(--small);
  font-size: var(--fontSize-medium);
  font-weight: 700;
  background-image: linear-gradient(0deg, hsl(var(--colour-kpp-charcoal-hsl)/0.9) 40%, transparent);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 20%, transparent);
  color: hsl(var(--colour-kpp-cream-hsl));
  transition: color 400ms ease;
}

.link-image:hover .image {
  filter: brightness(0.5);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  scale: 1.1;
}
.link-image:hover .title {
  color: hsl(var(--colour-highlight-hsl));
}

button,
.button {
  cursor: pointer;
  box-sizing: border-box;
  height: 3.5rem;
  padding-inline: var(--medium);
  background-color: hsl(var(--colour-kpp-cream-hsl));
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  font-weight: 700;
  font-size: 1rem;
  color: hsl(var(--colour-font-hsl));
  transition: scale 350ms ease;
}
button:not(:disabled):hover,
.button:not(:disabled):hover {
  scale: 1.05;
}
button:not(:disabled):active,
.button:not(:disabled):active {
  scale: 0.95;
}
button.button--feature,
.button.button--feature {
  border: none;
  background-color: hsl(var(--colour-highlight-hsl));
  box-shadow: var(--box-shadow);
}
button.button--alt,
.button.button--alt {
  border: none;
  background-color: hsl(var(--colour-kpp-cream-hsl));
  box-shadow: var(--box-shadow);
}
button:disabled,
.button:disabled {
  cursor: not-allowed;
  background-color: hsl(var(--colour-kpp-cream-dark-hsl));
  color: hsl(var(--colour-font-lighter-hsl));
  filter: saturate(0.25);
}
button.noBorder,
.button.noBorder {
  border: none;
  box-shadow: none;
}

.formField {
  display: flex;
  flex-direction: column;
  gap: var(--xsmall);
}

.formField:has(:is(input:focus, textarea:focus)) label {
  color: hsl(var(--colour-highlight-hsl));
}

.formField:has(input:is(:read-only, :disabled)):not(:has(.p-hidden-accessible)) label {
  color: hsl(var(--colour-highlight-hsl));
}

label,
.label {
  font-size: var(--fontSize-xsmall);
  color: hsl(var(--colour-kpp-cream-hsl));
  text-transform: uppercase;
  transition: color 250ms ease;
}

input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext),
textarea,
.p-inputtextarea {
  padding: var(--small) var(--small);
  border: none;
  border-radius: var(--border-radius);
  background-color: hsl(var(--colour-kpp-charcoal-light-hsl)/0.45);
  font-size: 1rem;
  color: hsl(var(--colour-font-lightest-hsl));
  transition: border 250ms ease;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext):focus,
textarea:focus,
.p-inputtextarea:focus {
  outline: 0;
  background-color: hsl(var(--colour-kpp-charcoal-light-hsl)/0.65);
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext).error,
textarea.error,
.p-inputtextarea.error {
  border-left: var(--small) solid hsl(var(--colour-highlight-hsl));
}

textarea {
  resize: vertical;
}

@media (max-width: 700px) {
  form .columns-2 {
    grid-template-columns: 1fr;
  }
  form .span-col2 {
    grid-column-end: span 1;
  }
}
.object-fit {
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.imgHover-wrapper {
  position: relative;
  height: 100%;
}
.imgHover-wrapper .imgHover {
  z-index: 2;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: hsl(var(--colour-kpp-charcoal-darkest-hsl)/0.6);
  color: hsl(var(--colour-kpp-cream-hsl));
  font-size: var(--fontSize-large);
  transition: opacity 350ms ease;
}
.imgHover-wrapper:hover .imgHover {
  opacity: 1;
}

.pageHeader-wrapper {
  grid-template-columns: repeat(3, 1fr);
}

#pageHeader-title {
  padding-block: var(--large);
  padding-left: var(--large);
}
#pageHeader-title h1 {
  font-size: var(--fontSize-large);
  line-height: 1;
  max-width: 9ch;
}

#pageHeader-desc {
  padding-block: var(--large);
}

@media (max-width: 1200px) {
  .pageHeader-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .pageHeader-wrapper > :nth-child(3) {
    grid-column-end: span 2;
  }

  #pageHeader-title {
    padding-left: var(--small);
  }
}
@media (max-width: 800px) {
  .pageHeader-wrapper {
    grid-template-columns: 1fr;
    row-gap: var(--medium);
  }

  #pageHeader-title {
    padding-bottom: 0;
    padding-left: 0;
  }

  #pageHeader-desc {
    padding-top: 0;
  }
}
.socialIcon {
  width: 4rem;
}
.socialIcon #circle-outline {
  stroke-dashoffset: 0;
  stroke: hsl(var(--colour-kpp-cream-hsl));
}
.socialIcon #icon {
  fill: hsl(var(--colour-kpp-cream-hsl));
}
.socialIcon-animate #circle-outline {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  fill: transparent;
  stroke-width: 4px;
  transition: stroke-dashoffset 500ms ease-in-out, stroke 350ms ease 250ms;
}
.socialIcon-animate #icon {
  transition: fill 350ms ease 250ms;
}
.socialIcon:hover #circle-outline {
  stroke-dashoffset: 0;
  stroke: hsl(var(--colour-kpp-cream-hsl));
}
.socialIcon:hover #icon {
  fill: hsl(var(--colour-kpp-cream-hsl));
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 1;
}
.hamburger[data-active=true]:hover {
  opacity: 1;
}
.hamburger[data-active=true] .hamburger-inner,
.hamburger[data-active=true] .hamburger-inner::before,
.hamburger[data-active=true] .hamburger-inner::after {
  background-color: hsl(var(--colour-kpp-cream-hsl));
}

.hamburger-box {
  width: 40px;
  height: 28px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: hsl(var(--colour-kpp-cream-hsl));
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -12px;
}
.hamburger-inner::after {
  bottom: -12px;
}

.hamburger--squeeze {
  --animation-duration: 0.25s;
}
.hamburger--squeeze .hamburger-inner {
  transition-duration: var(--animation-duration);
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top var(--animation-duration) 0.12s ease, opacity var(--animation-duration) ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom var(--animation-duration) 0.12s ease, transform var(--animation-duration) cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze[data-active=true] .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze[data-active=true] .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top var(--animation-duration) ease, opacity var(--animation-duration) 0.12s ease;
}
.hamburger--squeeze[data-active=true] .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom var(--animation-duration) ease, transform var(--animation-duration) 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (min-width: 700px) {
  .hamburger {
    display: none;
  }
}
.hero-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr 1fr;
  min-height: 80svh;
}

.hero-feature {
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr repeat(2, 1fr);
}
.hero-feature div {
  background-color: black;
}
.hero-feature .hero-feature-image {
  position: absolute;
  inset: 0;
  mix-blend-mode: screen;
}
.hero-feature .hero-feature-image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.hero-feature > div:nth-child(1) {
  grid-column-end: span 3;
}
.hero-feature > div:nth-child(3) {
  grid-column-end: span 2;
}

@media (min-width: 1400px) {
  .hero-feature {
    grid-row-end: span 3;
  }
}
@media (max-width: 1400px) {
  .hero-wrapper {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 2fr 1fr;
  }

  .hero-feature {
    grid-column-end: span 3;
  }
}
@media (max-width: 1000px) {
  .hero-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr 1fr;
  }

  .hero-feature {
    grid-column-end: span 2;
  }

  .hero-news {
    grid-column-end: span 2;
  }
}
@media (max-width: 700px) {
  .hero-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr 1fr;
  }

  .hero-feature {
    grid-column-end: span 1;
  }

  .hero-news {
    grid-column-end: span 1;
  }
}
.sectors-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 60svh;
}

.sectors-sectors {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.sectors-description {
  padding-block: var(--medium);
  padding-inline: var(--medium);
}

.sectors-sectorItem {
  min-height: 16vh;
  background-color: hsl(var(--colour-kpp-cream-hsl));
  display: grid;
  grid-template-columns: 1fr 33%;
  grid-template-rows: 1fr;
  overflow: hidden;
  container-type: size;
}

.sector-image {
  grid-area: 1/1/2/3;
  transition: scale 500ms ease, clip-path 500ms ease, filter 150ms ease;
}
.sector-image img {
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.sector-title {
  --fontSize: 10cqh;
  grid-area: 1/2/2/3;
  padding-top: var(--xsmall);
  padding-left: var(--small);
  writing-mode: vertical-lr;
  rotate: 180deg;
  font-size: var(--fontSize);
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  overflow: hidden;
  background-image: linear-gradient(90deg, hsl(var(--colour-kpp-charcoal-hsl)/0.8) 40%, transparent);
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 20%, transparent);
  color: hsl(var(--colour-kpp-cream-hsl));
  transition: color 400ms ease;
}

.sectors-sectorItem:hover .sector-image {
  filter: brightness(0.5);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  scale: 1.1;
}
.sectors-sectorItem:hover .sector-title {
  color: hsl(var(--colour-highlight-hsl));
}

@media (max-width: 1400px) {
  .sectors-wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }

  .sectors-sectors {
    order: 2;
  }
}
@media (max-width: 700px) {
  .sectors-sectors {
    grid-template-columns: 1fr;
  }
}
.slick-list {
  height: 100%;
  width: 100%;
}

.slick-track {
  height: 100%;
}

.slick-slide {
  width: 100vw;
}

.slick-slide > div {
  height: 100%;
}

.slick-dots {
  align-self: start;
  display: flex !important;
  flex-direction: row;
  position: relative;
  gap: 1px;
  bottom: 0;
  width: auto;
  padding-left: var(--small);
}
.slick-dots li {
  display: block;
  margin: 0;
  width: auto;
  height: auto;
}
.slick-dots li button {
  width: 29px;
  height: 4px;
  padding: 0px;
  background-color: hsl(var(--colour-kpp-cream-lightest-hsl)/0.5);
}
.slick-dots li button::before {
  display: none;
}
.slick-dots li.slick-active button {
  background-color: hsl(var(--colour-kpp-cream-hsl));
}

.heroSlider {
  display: grid;
  grid-template-rows: 1fr 5.25rem;
  height: 100%;
  overflow: hidden;
}
.heroSlider .slick-list {
  grid-area: 1/1/3/2;
}
.heroSlider .slick-dots {
  grid-area: 2/1/3/2;
}
.heroSlider .slideWrapper {
  height: 100%;
}
.heroSlider .slideWrapper-content {
  --height: 2rem;
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-areas: "news";
}
.heroSlider .slideWrapper-content img {
  grid-area: news;
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  scale: 1;
  animation: grow 10500ms linear infinite alternate;
}
.heroSlider .slideWrapper-content .newsTitle-wrapper {
  z-index: 2;
  grid-area: news;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 var(--small) var(--small) var(--small);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 20%, transparent 50%);
  font-size: var(--height);
}

.projects-wrapper {
  grid-template-columns: repeat(6, 1fr);
}

@media (max-width: 2600px) {
  .projects-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 2000px) {
  .projects-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  .projects-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 800px) {
  .projects-wrapper {
    grid-template-columns: 1fr;
  }
}
.projectSector-links {
  height: 14rem;
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  transition: all 500ms ease;
}
.projectSector-links a {
  overflow: hidden;
  border-radius: var(--border-radius);
}

.projectSector-links:has(a:nth-child(1):hover) {
  grid-template-columns: 9fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 9fr 1fr;
}
.projectSector-links:has(a:nth-child(1):hover) > .sectorLink:nth-child(1) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(1):hover) > .sectorLink:nth-child(1) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(2):hover) {
  grid-template-columns: 1fr 9fr 1fr 1fr 1fr;
  grid-template-rows: 9fr 1fr;
}
.projectSector-links:has(a:nth-child(2):hover) > .sectorLink:nth-child(2) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(2):hover) > .sectorLink:nth-child(2) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(3):hover) {
  grid-template-columns: 1fr 1fr 9fr 1fr 1fr;
  grid-template-rows: 9fr 1fr;
}
.projectSector-links:has(a:nth-child(3):hover) > .sectorLink:nth-child(3) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(3):hover) > .sectorLink:nth-child(3) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(4):hover) {
  grid-template-columns: 1fr 1fr 1fr 9fr 1fr;
  grid-template-rows: 9fr 1fr;
}
.projectSector-links:has(a:nth-child(4):hover) > .sectorLink:nth-child(4) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(4):hover) > .sectorLink:nth-child(4) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(5):hover) {
  grid-template-columns: 1fr 1fr 1fr 1fr 9fr;
  grid-template-rows: 9fr 1fr;
}
.projectSector-links:has(a:nth-child(5):hover) > .sectorLink:nth-child(5) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(5):hover) > .sectorLink:nth-child(5) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(6):hover) {
  grid-template-columns: 9fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 9fr;
}
.projectSector-links:has(a:nth-child(6):hover) > .sectorLink:nth-child(6) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(6):hover) > .sectorLink:nth-child(6) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(7):hover) {
  grid-template-columns: 1fr 9fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 9fr;
}
.projectSector-links:has(a:nth-child(7):hover) > .sectorLink:nth-child(7) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(7):hover) > .sectorLink:nth-child(7) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(8):hover) {
  grid-template-columns: 1fr 1fr 9fr 1fr 1fr;
  grid-template-rows: 1fr 9fr;
}
.projectSector-links:has(a:nth-child(8):hover) > .sectorLink:nth-child(8) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(8):hover) > .sectorLink:nth-child(8) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(9):hover) {
  grid-template-columns: 1fr 1fr 1fr 9fr 1fr;
  grid-template-rows: 1fr 9fr;
}
.projectSector-links:has(a:nth-child(9):hover) > .sectorLink:nth-child(9) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(9):hover) > .sectorLink:nth-child(9) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.projectSector-links:has(a:nth-child(10):hover) {
  grid-template-columns: 1fr 1fr 1fr 1fr 9fr;
  grid-template-rows: 1fr 9fr;
}
.projectSector-links:has(a:nth-child(10):hover) > .sectorLink:nth-child(10) img {
  filter: saturate(1);
}
.projectSector-links:has(a:nth-child(10):hover) > .sectorLink:nth-child(10) .sectorLink-title {
  width: 100%;
  height: auto;
  opacity: 1;
}

.sectorLink {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
}
.sectorLink img {
  grid-area: 1/1/3/2;
  filter: saturate(0);
}
.sectorLink .sectorLink-title {
  display: none;
  z-index: 2;
  grid-area: 1/1/3/2;
  display: flex;
  align-items: flex-end;
  padding: var(--xsmall);
  background-image: linear-gradient(0deg, hsl(var(--colour-kpp-charcoal-hsl)/0.9) 40%, transparent);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 20%, transparent);
  color: hsl(var(--colour-kpp-cream-hsl));
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: width 100ms ease, opacity 500ms ease 100ms;
}

.project {
  --startHeight: calc(100vh - var(--headerHeight) - (var(--small) * 3));
  position: relative;
  margin-inline: var(--small);
}
.project .headerWrapper {
  position: relative;
}
.project .headerWrapper-background {
  position: absolute;
  height: var(--startHeight);
  width: 100%;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
  border-radius: var(--border-radius);
  transition: height 0.5s ease;
}
.project .headerWrapper-background.shrink {
  height: 65vh;
}
.project .headerWrapper-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(6px);
  mix-blend-mode: multiply;
  transition: opacity 1s ease;
}
.project .headerWrapper-background img.hide {
  opacity: 0;
}
.project .headerContent {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--xlarge);
  min-height: var(--startHeight);
  margin-bottom: var(--small);
  padding: var(--medium);
  padding-top: var(--xlarge);
  color: hsl(var(--colour-kpp-cream-hsl));
  transition: min-height 0.5s ease;
}
.project .headerContent .projectTitle {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}
.project .headerContent .projectTitle h1 {
  font-size: var(--fontSize-xlarge);
  color: hsl(var(--colour-kpp-cream-hsl));
}
.project .headerContent .projectTitle h2 {
  z-index: 10;
  font-family: var(--fontFamily-feature);
  font-size: var(--fontSize-regular);
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
}
.project .headerContent .projectTitle h2 a {
  cursor: pointer;
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
}
.project .headerContent .projectTitle h2 a:hover {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.project .headerContent.shrink {
  min-height: 5rem;
}
.project .projectTitle-projectName span span {
  position: relative;
  opacity: 0;
  bottom: -1rem;
}
.project .projectTitle-projectName.animate span span {
  animation: load-title 0.75s forwards;
}
.project .projectDetails {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  align-content: end;
  column-gap: var(--xsmall);
  row-gap: var(--small);
  line-height: 1;
}
.project .projectDetails .projectDetail-label {
  font-size: var(--fontSize-xsmall);
  text-transform: uppercase;
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
  opacity: 0;
  bottom: -1rem;
  translate: 0 3px;
}
.project .projectDetails .projectDetail-label.animate {
  animation: load-detail 0.75s forwards;
}
.project .projectDetails .projectDetail-value {
  opacity: 0;
  bottom: -1rem;
  text-align: right;
}
.project .projectDetails .projectDetail-value.animate {
  animation: load-detail 0.75s forwards;
}
.project .projectDetails a {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.project img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.project .imageWrapper {
  width: calc(100% - var(--medium) * 2);
  height: 65vh;
  margin-inline: var(--medium);
  border-radius: var(--border-radius);
  overflow: hidden;
}
.project .imageWrapper img {
  opacity: 0;
  translate: 0 5rem;
}
.project .imageWrapper img.animate {
  animation: load-image 0.75s forwards 0.25s;
}
.project .description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: minmax(200px, auto);
  align-items: center;
}
.project .description p {
  padding-inline: var(--xlarge);
}
.project .description .imgHover-wrapper {
  height: min(35vh, 400px);
}
.project .quote {
  display: flex;
  background-color: hsl(var(--colour-kpp-cream-hsl));
  align-items: center;
  justify-content: center;
  grid-column-end: span 2;
  padding: var(--xlarge);
  margin-bottom: var(--xlarge);
}
.project .quote p {
  padding: 0;
  font-size: var(--fontSize-medium);
  font-family: var(--fontFamily-feature);
}
.project .partners img {
  max-height: 120px;
  max-width: 220px;
  filter: saturate(0) brightness(100%);
}
.project .overflow-gallery {
  grid-auto-rows: 15vh;
}
@keyframes load-title {
  0% {
    bottom: -1rem;
    opacity: 0;
  }
  50% {
    bottom: 0.2rem;
    opacity: 0.7;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes load-detail {
  0% {
    bottom: -1rem;
    opacity: 0;
  }
  50% {
    bottom: 0.2rem;
    opacity: 0.7;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes load-image {
  0% {
    translate: 0 5rem;
    opacity: 0;
  }
  100% {
    translate: 0 0;
    opacity: 1;
  }
}

.moreItems-wrapper {
  margin-inline: var(--small);
}

@media (max-width: 1200px) {
  .project .description p {
    padding-inline: var(--large);
  }
}
@media (max-width: 1000px) {
  .project .headerContent {
    grid-template-columns: 1fr;
    row-gap: var(--large);
  }
  .project .projectDetails {
    display: grid;
    grid-template-columns: auto 1fr;
  }

  .moreItems-wrapper .columns-3 {
    grid-template-columns: 1fr;
  }
  .moreItems-wrapper .projectItem .projectItem-image {
    height: max(20vh, 200px);
  }
}
@media (max-width: 850px) {
  .project .description p {
    padding-inline: var(--medium);
  }
}
@media (max-width: 700px) {
  .project .headerWrapper-background.shrink {
    height: 60vh;
  }
  .project .headerContent {
    padding: var(--small);
    padding-top: var(--large);
  }
  .project .imageWrapper {
    height: 40vh;
    width: calc(100% - var(--small) * 2);
    margin-inline: var(--small);
  }
  .project .description {
    grid-template-columns: 1fr;
  }
  .project .description .text {
    padding-block: var(--medium);
  }
  .project .description .span-col2 {
    grid-column-end: span 1;
  }

  .moreItems-wrapper .projectItem .projectItem-image {
    height: max(20vh, 175px);
  }
}
.projectItem-wrapper {
  position: relative;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
}
.projectItem-wrapper::before {
  content: "";
  position: absolute;
  top: -16px;
  left: -16px;
  width: 32px;
  height: 32px;
  margin-top: 25%;
  margin-left: 50%;
  border: 3px solid hsl(var(--colour-kpp-cream-hsl)/0.3);
  border-bottom-color: hsl(var(--colour-kpp-cream-hsl));
  border-radius: 50%;
  animation: loadingRotation 1s linear infinite;
}

.projectItem {
  --height: 1.5rem;
  --bgColour: var(--colour-kpp-cream-hsl);
  --fgColour: var(--colour-kpp-charcoal-hsl);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}
.projectItem .projectItem-image {
  height: max(25vh, 300px);
  overflow: hidden;
}
.projectItem img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.9) saturate(0.9);
  scale: 1;
  transition: filter 350ms ease, scale 1250ms ease;
}
.projectItem .title-wrapper {
  background-color: hsl(var(--bgColour));
}
.projectItem .title {
  font-size: var(--height);
  color: hsl(var(--fgColour));
  z-index: 2;
}
.projectItem .subTitle {
  z-index: 2;
  font-weight: 300;
  color: hsl(var(--fgColour));
  transition: opacity 350ms ease;
}
.projectItem .loadingSpinner {
  grid-area: 1/1/4/2;
  align-self: center;
  justify-self: center;
}
.projectItem:hover img {
  scale: 1.15;
  filter: brightness(1) saturate(1);
}
.projectItem:hover .circle-link #circle-outline,
.projectItem:hover .circle-link #arrow-line,
.projectItem:hover .circle-link #arrow-head-top,
.projectItem:hover .circle-link #arrow-head-bottom {
  stroke-dashoffset: 0;
  stroke: hsl(var(--fgColour));
}

.circle-link {
  height: 2rem;
  width: auto;
}
.circle-link #circle-outline,
.circle-link #arrow-line,
.circle-link #arrow-head-top,
.circle-link #arrow-head-bottom {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  fill: transparent;
  stroke-width: 7px;
  transition: stroke-dashoffset 500ms ease-in-out, stroke 350ms ease 250ms;
}
.circle-link #arrow-line {
  transition: stroke-dashoffset 300ms ease-in-out, stroke 350ms ease 250ms;
}
.circle-link #arrow-head-top,
.circle-link #arrow-head-bottom {
  transition: stroke-dashoffset 300ms ease-in-out 200ms, stroke 350ms ease 250ms;
}

.project-reveal {
  opacity: 0;
  animation: animation-projectReveal 1s forwards;
}

@keyframes animation-projectReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.newsWrapper {
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  gap: var(--large);
}

.newsContent .project {
  height: 25vh;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
}
.newsContent .recentNews .project {
  height: 50vh;
}

.news {
  --startHeight: calc(100vh - var(--headerHeight) - (var(--small) * 3));
  position: relative;
  margin-inline: var(--small);
}
.news .headerWrapper {
  position: relative;
}
.news .headerWrapper-background {
  position: absolute;
  height: var(--startHeight);
  width: 100%;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
  border-radius: var(--border-radius);
  transition: height 0.5s ease;
}
.news .headerWrapper-background.shrink {
  height: 65vh;
}
.news .headerWrapper-background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(6px);
  mix-blend-mode: multiply;
  transition: opacity 1s ease;
}
.news .headerWrapper-background img.hide {
  opacity: 0;
}
.news .headerContent {
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: var(--xlarge);
  min-height: var(--startHeight);
  margin-bottom: var(--small);
  padding: var(--medium);
  padding-top: var(--xlarge);
  color: hsl(var(--colour-kpp-cream-hsl));
  transition: min-height 0.5s ease;
}
.news .headerContent .projectTitle {
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 100%;
}
.news .headerContent .projectTitle h1 {
  font-size: var(--fontSize-xlarge);
  color: hsl(var(--colour-kpp-cream-hsl));
}
.news .headerContent .projectTitle h2 {
  z-index: 10;
  font-family: var(--fontFamily-feature);
  font-size: var(--fontSize-regular);
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
}
.news .headerContent .projectTitle h2 a {
  cursor: pointer;
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
}
.news .headerContent .projectTitle h2 a:hover {
  color: hsl(var(--colour-kpp-cream-hsl));
}
.news .headerContent.shrink {
  min-height: 5rem;
}
.news .projectTitle-projectName span span {
  position: relative;
  opacity: 0;
  bottom: -1rem;
}
.news .projectTitle-projectName.animate span span {
  animation: load-title 0.75s forwards;
}
@keyframes load-title {
  0% {
    bottom: -1rem;
    opacity: 0;
  }
  50% {
    bottom: 0.2rem;
    opacity: 0.7;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
.news .projectDetails {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 20ch);
  align-content: end;
  align-items: end;
  column-gap: var(--xsmall);
  row-gap: var(--small);
  line-height: 1;
}
.news .projectDetails .projectDetail-label {
  font-size: var(--fontSize-xsmall);
  text-transform: uppercase;
  text-align: right;
  color: hsl(var(--colour-kpp-cream-hsl)/0.3);
  opacity: 0;
  bottom: -1rem;
}
.news .projectDetails .projectDetail-label.animate {
  animation: load-detail 0.75s forwards;
}
.news .projectDetails .projectDetail-value {
  display: flex;
  gap: var(--xsmall);
  translate: 0 1px;
  opacity: 0;
  bottom: -1rem;
}
.news .projectDetails .projectDetail-value.animate {
  animation: load-detail 0.75s forwards;
}
@keyframes load-detail {
  0% {
    bottom: -1rem;
    opacity: 0;
  }
  50% {
    bottom: 0.2rem;
    opacity: 0.7;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
.news .projectContent {
  background-color: hsl(var(--colour-background-hsl));
}
.news img {
  height: 0;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
@keyframes load-image {
  0% {
    translate: 0 5rem;
    opacity: 0;
  }
  100% {
    translate: 0 0;
    opacity: 1;
  }
}
.news .imageWrapper {
  width: 100%;
  height: 70vh;
  padding-inline: var(--medium);
}
.news .imageWrapper img {
  opacity: 0;
  translate: 0 5rem;
}
.news .imageWrapper img.animate {
  animation: load-image 0.75s forwards 0.25s;
}
.news .detailsWrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  align-items: center;
  column-gap: var(--large);
  row-gap: var(--large);
  margin-block: var(--large) var(--xlarge);
}
.news .detailsWrapper .detailsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: relative;
}
.news .detailsWrapper .detailsIcon {
  width: min(64px, 80%);
  margin-bottom: var(--medium);
}
.news .detailsWrapper .detailsValue-wrapper {
  position: relative;
}
.news .detailsWrapper .detailsValue-header {
  position: absolute;
  bottom: 2rem;
  left: 0.7rem;
  font-size: var(--fontSize-xsmall);
  font-weight: 700;
  color: hsl(var(--colour-background-dark-hsl));
  line-height: 1rem;
}
.news .detailsWrapper .detailsValue {
  display: flex;
  align-items: center;
  height: 2rem;
  padding: 0 var(--small);
  border-radius: 1rem;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
  color: hsl(var(--colour-kpp-cream-hsl));
}
.news .summary {
  line-height: 2;
  font-size: 2rem;
}
.news .summary b,
.news .summary strong {
  color: hsl(var(--colour-kpp-cream-darker-hsl));
}
.news .gallery {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 200px;
  gap: var(--xsmall);
  overflow: hidden;
}
.news .gallery > div:nth-child(1n) {
  grid-column-end: span 4;
}
.news .gallery > div:nth-child(2n) {
  grid-column-end: span 2;
}
.news .gallery > div:nth-child(3n) {
  grid-column-end: span 6;
}
.news .gallery > div:nth-child(4n) {
  grid-column-end: span 3;
}
.news .gallery > div:nth-child(5n) {
  grid-column-end: span 3;
}

.newsItem-wrapper {
  position: relative;
  background-color: hsl(var(--colour-kpp-charcoal-hsl));
}
.newsItem-wrapper::before {
  content: "";
  position: absolute;
  top: -16px;
  left: -16px;
  width: 32px;
  height: 32px;
  margin-top: 25%;
  margin-left: 50%;
  border: 3px solid hsl(var(--colour-kpp-cream-hsl)/0.3);
  border-bottom-color: hsl(var(--colour-kpp-cream-hsl));
  border-radius: 50%;
  animation: loadingRotation 1s linear infinite;
}

.newsItem {
  --height: 1.5rem;
  --bgColour: var(--colour-kpp-cream-hsl);
  --fgColour: var(--colour-kpp-charcoal-hsl);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;
}
.newsItem .newsItem-image {
  height: max(25vh, 300px);
  overflow: hidden;
}
.newsItem img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(0.9) saturate(0.9);
  scale: 1;
  transition: filter 350ms ease, scale 1250ms ease;
}
.newsItem .title-wrapper {
  background-color: hsl(var(--bgColour));
}
.newsItem .title {
  font-size: var(--height);
  color: hsl(var(--fgColour));
  z-index: 2;
}
.newsItem .subTitle {
  z-index: 2;
  font-weight: 300;
  color: hsl(var(--fgColour));
  transition: opacity 350ms ease;
}
.newsItem .loadingSpinner {
  grid-area: 1/1/4/2;
  align-self: center;
  justify-self: center;
}
.newsItem:hover img {
  scale: 1.15;
  filter: brightness(1) saturate(1);
}
.newsItem:hover .circle-link #circle-outline,
.newsItem:hover .circle-link #arrow-line,
.newsItem:hover .circle-link #arrow-head-top,
.newsItem:hover .circle-link #arrow-head-bottom {
  stroke-dashoffset: 0;
  stroke: hsl(var(--fgColour));
}

.news-reveal {
  opacity: 0;
  animation: animation-newsReveal 1s forwards;
}

@keyframes animation-newsReveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-wrapper {
  grid-template-columns: repeat(6, 1fr);
}

:is(#phoneBlock, #emailBlock, #addressBlock) {
  grid-column-end: span 2;
}

:is(#formBlock, #mapBlock) {
  grid-column-end: span 3;
}

#mapBlock {
  min-height: 400px;
}

@media (max-width: 1200px) {
  :is(#phoneBlock, #emailBlock, #addressBlock, #mapBlock) {
    grid-column-end: span 3;
  }

  #mapBlock {
    order: 4;
  }

  #formBlock {
    grid-column-end: span 6;
    order: 5;
  }
}
@media (max-width: 800px) {
  .contact-wrapper {
    grid-template-columns: 1fr;
  }

  :is(#phoneBlock, #emailBlock, #addressBlock, #mapBlock, #formBlock) {
    grid-column-end: span 1;
  }
}
